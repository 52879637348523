import React from "react"
import styled from 'styled-components'

const ImageWrapper = styled.div`
  width: ${ props => props.width || 100 }%;
  padding-top: ${ props => props.width || 100 }%;
  position: relative;
  background-color: rgba( 0, 0, 0, 0.5 );
`

const Picture = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const Image = props => {

  if ( !props.src ) return <div/>

  return(
    <ImageWrapper 
      width = { props.width }
      style = { props.style }
      alt = { props.alt }
    >
      <Picture 
        alt = { props.alt || 'Home of Party Essentials' }
        src = { props.src }
      />
    </ImageWrapper>
  )
}