import React from 'react'
import styled from 'styled-components'

//
import { Link } from 'gatsby'

const BannerWrapper = styled.div`
    width: 100vw;
    height: 100vw;
    background-color: ${ props => props.backgroundColor || 'rgba( 0, 50, 100, 0.8 )' };
    display: flex;
    flex-direction: column;

    @media (min-width: 1080px){
        height: 27.5vw;
        flex-direction: row;
    }
`

const Image = styled.div`
    width: 100vw;
    height: ${ 125 / 3 }vw;
    background-image: ${ props => props.src ? `url( ${ props.src } )` : 'none' };
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media ( min-width: 1080px ){
        width: 60vw;
        height: 27.5vw;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    width: 100vw;
    height: ${ 175 / 3 }vw;

    @media ( min-width: 1080px ){
        flex-direction: column;
        width: 40vw;
        height: 27.5vw;
    }
`

const MessageWrapper = styled.div`
    flex: 8;
    display: flex;
    padding: 1vw;
    align-items: flex-end;
`

const ButtonWrapper = styled.div`
    flex: 2;
    display: flex;
    padding: 1vw;
`

const Button = styled( Link )`
    padding: 2.5%;
    margin-bottom: 2%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: ${ props => props.color || 'rgba( 0, 0, 0, 0.1 )' };
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s linear;

    :active& {
        opacity: 0.8
    }
`


export const Banner2 = props => {
    return(
        <BannerWrapper
            backgroundColor = { props.backgroundColor }
        >
            <Image
                src = { props.image1 }
            />
            <ContentWrapper>
                <MessageWrapper>
                    {
                        props.message
                    }
                </MessageWrapper>
                <ButtonWrapper>
                    <Button
                        to = { props.href }
                        color = { props.backgroundColor }
                    >
                        SHOP NOW
                    </Button>
                </ButtonWrapper>
            </ContentWrapper>
        </BannerWrapper>
    )
}