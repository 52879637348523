import React, { useState } from 'react'
import { Link } from 'gatsby'

// Components
import { Flag, Social } from '../components'
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail'

const special = process.env.GATSBY_MODE === 'REGULAR' ? false : true

const color = special ? 'white' : 'grey'
const textShadow = special ? '0 0 2px rgba( 0, 0, 0, 0.5 )' : 'none'

const NavLink = props => (
    <li>
        <Link 
            className = 'nav-link' 
            to = { props.to } 
            style = {{ 
                color : color, 
                textShadow : textShadow, 
                display: props.hidden ? 'none' : 'block' 
            }}
        >
            { props.title }
        </Link>
    </li>
)


const Footer = props => {

    const [ displayAuthor, setDisplayAuthor ] = useState( false )

    return (
        <footer className='site-footer'
            style = {{
                backgroundColor : special ? '#7986cb' : '#d1c4e9',
            }}
        >
            <div className='footer_inner'>
                <div className='container'>
                    <div className='footer-widget footer-content'>
                        <section id='nav_menu-8' className='widget widget_nav_menu'>
                            <div className='menu-main-container'>
                                <ul id='menu-main' className='menu'>
                                    <li><Social type = { 'facebook' }/></li>
                                    <li><Social type = { 'youtube' }/></li>
                                </ul>
                                <ul id='menu-main' className='menu'>
                                    <li style = {{ color: color }}><PhoneIcon color = { color }/>+1 (604) 275-5428</li>
                                </ul> 
                                <ul id='menu-main' className='menu'>
                                    <li style = {{ color: color }}><PhoneIcon style = {{ color: color }}/>Toll free +1 (800) 383-4634</li> 
                                </ul>
                                <ul id='menu-main' className='menu'>
                                <   li style = {{ color: color }}><PhoneIcon style = {{ color: color }}/>Fax +1 (604) 275-5968</li> 
                                </ul>
                                <ul id='menu-main' className='menu'>
                                    <li style = {{ color: color }}><MailIcon style = {{ color: color }}/><a  style = {{ color: color }} href = {'mailto:shop@siunsons.com'}>shop@siunsons.com</a></li>
                                </ul>
                                <ul id='menu-main' className='menu'>
                                    <li><Flag country = { 'ca' }/></li>
                                    <li><Flag country = { 'us' }/></li>
                                </ul>
                                <ul id='menu-main' className='menu'>
                                    <NavLink to = '/terms' title = { 'Terms of use' } />
                                    <NavLink to = '/termsOfSale' title = { 'Terms of sale' } />
                                    <NavLink to = '/privacy' title = { 'Privacy Policy' } />
                                    <NavLink to = '/faq' title = { 'FAQ' } />
                                    <NavLink to = '/disclaimer' title = { 'Disclaimer' } />
                                    <NavLink to = '/payment' title = { 'Payment Method' } />
                                    <NavLink to = '/pages/product-recall' title = { 'Product Recall' } />
                                </ul>
                            </div>
                        </section>
                    </div>
                    <div className='footer-bottom social-right-menu '>
                        <div 
                            className='site-info'
                            onDoubleClick = { () => setDisplayAuthor( !displayAuthor ) }
                            style = {{
                                cursor: 'pointer',
                                fontWeight: 'bold', 
                                color: color,
                                textShadow: textShadow
                            }}
                        >
                            © { new Date().getFullYear() }, Siu &amp; Sons International Trading Corporation<br/>
                            All right reserved.
                            <br/>
                            <span
                                style = {{
                                    transition: '0.5s linear',
                                    color: displayAuthor ? '#ff9800' : '#d1c4e9'
                                }}
                            >
                                Designed By Utopia
                            </span>
                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
