import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import instagram from "../../images/Instagram.svg"

export const Instagram = props => {
    return(
        <StaticQuery
            query=
                {
                    graphql`
                        query InstagramQuery{
                            allDatoCmsSocial {
                                edges {
                                    node {
                                        instagram
                                    }
                                }
                            }
                        }
                    `
                }
            render = { data => {
                return (
                    <a href = { data.allDatoCmsSocial.edges[ 0 ].node.instagram }>
                        <img 
                            width = { '40' } 
                            src = { instagram }
                            alt = { 'Instagram' }
                        />
                    </a>
                )
            }}
        />
    )
}