import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import facebook from "../../images/Facebook.svg"

export const Facebook = () => {
    return(
        <StaticQuery
            query=
                {
                    graphql`
                        query FacebookQuery{
                            allDatoCmsSocial {
                                edges {
                                    node {
                                        facebook
                                    }
                                }
                            }
                        }
                    `
                }
            render = { data => {
                return (
                    <a href = { data.allDatoCmsSocial.edges[ 0 ].node.facebook }>
                        <img 
                            width = { '40' } 
                            src = { facebook }
                            alt =  { 'Facebook' }
                        />
                    </a>
                )
            }}
        />
    )
}