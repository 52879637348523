import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const Wrapper = styled.div`
    width : ${ props => props.width ? props.width : '100%' };
    height : ${ props => props.height ? props.height : '100%' };
    display : flex;
    justify-content : center;
    align-items : center;
`

export const Loader = props => {
    return <Wrapper height = { props.height } width = { props.width } style = { props.style }><CircularProgress /></Wrapper>
}