import styled from 'styled-components'

export const Wrapper = styled.div`
    height : 100vh;
    width : 100vw;
    display : flex;
    flex-direction : ${ props => props.column ? 'column' : 'row' };
    justify-content : ${ props => props.center ? 'center' : 'flex-start' };
    align-items : ${ props => props.center ? 'center' : 'stretch' };
    backdrop-filter: ${ props => props.clear ? 'none' : 'blur( 5px )' };
    background-color : ${ props => props.clear ? 'transparent' : 'rgba( 255, 255, 255, 0 )' }
`

export const Span = styled.div`
    height : 88vh;
    width : ${ props => props.nav ? '23vw' : '73vw' };
    display : grid;
    grid-template-columns: ${ props => props.nav ? '23vw' : '23vw 23vw 23vw' };
    grid-template-rows: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% ;
    background-color : rgba( 255, 255, 255, 0.1 );
    box-shadow : 0 0 10px grey;
    border-radius : 5px;
    margin : 11vh 1vw 1vh 1vw;
    justify-content: space-between;
    grid-auto-flow: column;
`
export const Container = styled.div`
    width : ${ props => props.width ? props.width : '100%' };
    height : ${ props => props.height ? props.height : '100%' };
    display : flex;
    justify-content : ${ props => props.left ? 'flex-start' : 'center' };
    align-items : center;
`

