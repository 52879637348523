import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SEO from './seo'
import "./layout.css"

export const Layout = ({ children, description, index, structuredData, title  }) => (
  
  <StaticQuery
    query = {
      graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `
    }

    render={ data => (
      <>
        <Header 
          siteTitle = { data.site.siteMetadata.title } 
        />
        <div>
          <main>
            <div 
              style = {{ 
                height: '15vh' 
              }}
            />
            <SEO 
              title = { title }
              index = { index }
              description = { description }
              structuredData = { structuredData }
            />
            { children }
          </main>
        </div>
        <Footer />
      </>
    )}
  />
)

export default Layout
