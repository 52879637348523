import React, { useState } from "react"
import styled from 'styled-components'
import { Input } from './index'
import SearchIcon from '@material-ui/icons/Search'
import { StaticQuery, graphql} from "gatsby"
import { useFlexSearch } from 'react-use-flexsearch'

// Components
import { Product } from '../components'

const Modal = styled.div`
    width: 100vw;
    height : 100vh;
    padding : 20vh;
    background-color: rgba( 255, 255, 255, 1);
    backdrop-filter: blur( 5px );
    transition: 0.3s linear;
    position : fixed;
    top:0;
    left:0;
    z-index : 900;
    display: ${ props => props.visible ? 'flex' : 'none' };
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll
`

export const SearchModal = props => {
    return (
        <StaticQuery
            query = { 
                graphql`
                    query SearchProductQuery {
                        localSearchProducts {
                            store
                            index
                        }
                    }
                `
            }
            render = { data => {
                return (
                    <LocalSearchWrapper data = { data } visible = { props.visible } />
                )
            }}
        />
    )
}

const LocalSearchWrapper = props => {

    const [ query, setQuery ] = useState( '' )
    const results = useFlexSearch( query, props.data.localSearchProducts.index, props.data.localSearchProducts.store )

    return(
        <Modal
            visible = { props.visible }
        >
            <h2>Search</h2>
            <Input 
                style = {{ 
                    height : '10vh',
                    width: '90vw',
                    maxHeight : '80px',
                    maxWidth : '500px'
                }}
                icon = {
                    <SearchIcon/>
                }
                reverse
                value = { query }
                maxLength  = { 30 }
                onChange = { e => setQuery( e.target.value ) }
            />
            <br/>
            {
                        query === '' ? 
                        <h3>Keyword, SKU or UPC Barcode</h3>
                        :
                        results.length ? 
                        <div 
                            className = 'container'
                        >
                            <div 
                                className="row product-main"
                            >
                                { 
                                    results.map( ( product, index ) => <Product key = { index } image = { product.image } title = { product.title } handle = { product.handle } />)
                                }
                            </div>
                        </div>
                        :
                        <h3>No search result for { query }</h3>
                    }
        </Modal>
    )
}