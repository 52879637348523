import React from 'react'

// Component
import { Facebook } from './facebook'
import { Instagram } from './instagram'
import { Youtube } from './youtube'

export const Social = props => {

    if ( props.type === 'instagram' ) return <Instagram />
    if ( props.type === 'youtube' ) return <Youtube />
    if ( props.type === 'facebook' ) return <Facebook />
    
    return <div/>
    
}
