import React, { useState } from 'react'
import '../css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.css'
import '../css/style.css'
import logo from '../images/logo.png'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { SearchModal } from './search'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'gatsby'

// components
import { Flag } from '../components'

// Icon
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const special = process.env.GATSBY_MODE === 'REGULAR' ? false : true
const color = special ? 'white' : 'grey'

const Logo = styled.img`
  height : 8vh;
  width: auto;
  margin: 1vh;
`

const NavLink = props => (
  <Link 
    className = 'nav-link' 
    to = { props.to } 
    style = {{ 
      color : color, 
      textShadow : special ? '0 0 2px rgba( 0, 0, 0, 0.5 )' : 'none', 
      display: props.hidden ? 'none' : 'block' 
    }}
  >
    { props.title }
  </Link>
)

const LoginButton = props => {

  return(
    <div 
      style = {{ 
        backgroundColor: '#8bc34a', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        color: color,
        borderRadius: special ? 0: '1vh' ,
        textShadow : special ? '0 0 2px rgba( 0, 0, 0, 0.5 )' : 'none',
      }}
    >
      &nbsp;&nbsp;Login&nbsp;
      <ArrowForwardIcon/>
      &nbsp;&nbsp;
        <Flag country = { 'ca' } />
        &nbsp;&nbsp;
        <Flag country = { 'us' } />
        &nbsp;&nbsp;
    </div>
  )
}

const Header = props => {

  const [ isOpened, setIsOpened ] = useState( false )

  return(
    <>
      <div
        style = {{
          height: '5vh'
        }}
      >

      </div>
      <Navbar 
        expand='xl'
        fixed = { 'top' }
        style = {{ 
          backgroundColor : special ? '#7986cb' : '#d1c4e9',
          boxShadow: '0 0 10px grey',
          padding: special ? '0rem 1rem' : '1rem 1rem'
        }}
      >
        <Navbar.Brand href='/'>
          <Logo src = { logo }/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav' style = {{ justifyContent: 'flex-end' }}>
            <Nav>
              <NavLink to = '/login' title = { 'Login / Register' } hidden/>
              <NavLink to = '/contact' title = { 'Contact Us' } hidden />
              <NavLink to = '/catalog' title = { 'Catalog' } hidden />
              <NavLink to = '/home' title = { 'Home' } />
              <NavLink to = '/about' title = { 'About' } />
              <NavLink to = '/story' title = { 'Our Story' } />
              <NavLink to = '/service' title = { 'Service' } />
              <NavLink to = '/collection' title = { 'Collection' } />
              <NavLink to = '/collection/web-only-specials' title = { 'Web Specials' } />
              <NavLink to = '/blogs' title = { 'Blog' } />
              <NavLink to = '/contact' title = { 'Contact Us' } />
              <a 
                className = 'nav-link'  
                style = {{ 
                  color : color,
                  cursor: 'pointer',
                  textShadow : special ? '0 0 2px rgba( 0, 0, 0, 0.5 )' : 'none', 
                }} 
                href = 'https://cdn.shopify.com/s/files/1/0077/2505/6077/files/siunsons2024catalog.pdf' 
              >
               Catalog
              </a>
              <div 
                className = 'nav-link'  
                style = {{ 
                  color : color, 
                  cursor: 'pointer' 
                }}
              >
                {
                  isOpened?
                  <CloseIcon onClick = { () => setIsOpened( !isOpened )} />
                  :
                  <SearchIcon onClick = { () => setIsOpened( !isOpened )} />
                }  
                &nbsp;
              </div>
              <LoginButton />
            </Nav>
          </Navbar.Collapse> 
      </Navbar>
      <SearchModal visible = { isOpened }/>
    </>
  )
}

export default Header
