import React from 'react'
import styled from 'styled-components'

// Components 
import Carousel from 'react-bootstrap/Carousel'
import { Banner1 } from './variantOne'
import { Banner2 } from './variantTwo'
import { Banner3 } from './variantThree'
import { Banner4 } from './variantFour'

// Images
import Image11 from './image/11.jpg'
import Image21 from './image/21.jpg'
import Image22 from './image/22.jpg'
import Image23 from './image/23.jpg'
import Image31 from './image/31.jpg'
import Image32 from './image/32.jpg'
import Image33 from './image/33.jpg'
import Image41 from './image/41.jpg'
import Image42 from './image/42.jpg'
import Image43 from './image/43.jpg'
import Image51 from './image/51.jpg'
import Image52 from './image/52.jpg'
import Image53 from './image/53.jpg'

const Banner1Text = styled.h1`
    font-size: 7vw;
    color: white;

    @media (min-width: 992px) {
        font-size: 3.5vw;
    }
`

const Banner2Text = styled.h1`
    font-size: 9vw;
    color: white;

    @media (min-width: 992px) {
        font-size: 5vw;
    }
`

const Banner3Text = styled.h1`
    font-size: 7vw;
    color: white;
    line-height: 10vw;

    @media (min-width: 992px) {
        font-size: 3vw;
        line-height: 4rem;
    }
`

export const Banner = props => {


    return(
        <>
        <Carousel
            interval = { 3000 }
            controls = { !true }
            indicators = { true }
        >
            <Carousel.Item>
                <Banner2
                    image1 = { Image11 }
                    backgroundColor = { '#d4b169' }
                    message = {
                        <Banner1Text >Home of Party <br/>Essentials and more</Banner1Text>
                    }
                    href = { '/collection' }
                />
            </Carousel.Item>
            <Carousel.Item>
                <Banner1
                    image1 = { Image31 }
                    image2 = { Image32 }
                    image3 = { Image33 }
                    message = {
                        <Banner2Text >Baking Party <br/><span style = {{ color: 'yellow'}}>Solution</span></Banner2Text>
                    }
                    backgroundColor = { '#f58e89' }
                    href = { '/collection/candles-cake-pop-candy' }
                />
            </Carousel.Item>
            <Carousel.Item>
                <Banner1
                    image1 = { Image21 }
                    image2 = { Image22 }
                    image3 = { Image23 }
                    message = {
                        <Banner3Text>Premium Quality<br/>Special Shapes and Designs<br/>Color of your choice</Banner3Text>
                    }
                    backgroundColor = { '#31c5f4' }
                    href = { '/collection/balloon' }
                />
            </Carousel.Item>
            <Carousel.Item>
                <Banner3 
                    image1 = { Image41 }
                    image2 = { Image43 }
                    image3 = { Image42 }
                    href1 = { '/collection/luau-princess' }
                    href2 = { '/collection/spring-summer' }
                />
            </Carousel.Item>
            <Carousel.Item>
                <Banner4
                    image1 = { Image51 }
                    image2 = { Image53 }
                    image3 = { Image52 }
                    href1 = { 'https://canada.siunsons.com/account/login' }
                    href2 = { 'https://international.siunsons.com/account/login' }
                />
            </Carousel.Item>
        </Carousel>
        <div style = {{ height: '10vh' }} />
        </>
    )
}

