import React from 'react'
import { Link } from 'gatsby'

// Components
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

export const Blog = props => {
    return(
        <Link  
            to = { props.to } 
            className = { props.className }
            
        >
            <div
                style = { props.style }
            >
            <Card
                style = {{
                    borderRadius: 0,
                    boxShadow: '0 0 3px grey'
                }}
            >
                <div
                    style = {{
                        width: '100%',
                        paddingTop: '100%',
                        position: 'relative'
                    }}
                >
                    <Card.Img 
                        variant = 'top'
                        src = { props.image ? props.image : null } 
                        style = {{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        }}
                    />
                </div>
                <Card.Body>
                <div
                    style = {{
                        width: '100%',
                        paddingTop: '65%',
                        position: 'relative'
                    }}
                >
                    <p
                        style = {{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            
                        }}
                    >
                        { props.title }
                    </p>
                </div>
                <Button
                 style = {{
                     width: '100%',
                     borderRadius: 0,
                     backgroundColor: '#ff5722',
                     border:0,
                 }}
                >
                    Read more
                </Button>
                </Card.Body>
                <Card.Footer
                    style = {{
                        borderRadius: 0,
                        backgroundColor: 'white',
                        border: 0,
                    }}
                >
                <small 
                    className="text-muted"
                >
                    <strong style = {{ color: 'grey' }} >Created at { props.date }</strong>
                </small>
                </Card.Footer>
            </Card>
            </div>
        </Link>
    )
}