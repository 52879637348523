import React from 'react'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

// Component
import { Product } from './product'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 767 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
}

export const Suggestion = props => {

    if( props.data === {} ) return <div/>

    return(
        <>
        {
            props.title && <h3>{ props.title }</h3>
        }
        <Carousel 
          responsive = { responsive }
          infinite ={ true }
        >
            {
                props.data.map( ( product, index ) => {
                    return (
                        <Product 
                            key = { index }
                            image = { product.image }
                            title = { product.title }
                            handle = { product.handle }
                        />
                    )
                })
            }
        </Carousel>
        </>
    )
}