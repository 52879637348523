import React from 'react' 
import styled from 'styled-components'

const Wrapper = styled.div`
    width : ${ props => props.width ? props.width : '100%' };
    height : ${ props => props.height ? props.height : '100%' };
    display : flex;
    justify-content : center;
    align-items : center;
    position : relative;
`

const InnerWrapper = styled.div`
    width : 90%;
    height : 90%;
    display : flex;
    flex-direction : ${ props => props.reverse ? 'row-reverse' : 'row' };
    align-items : stretch;
    cursor : pointer;
    overflow : hidden;
    box-shadow: 0 0 2px grey;
    background-color : rgba( 255, 255, 255, 1 );
    transition : 0.3s linear;
    box-shadow : 0 0 5px grey;

    :hover& {
        opacity : 0.8
    }
`

const IconWrapper = styled.div`
    flex : 2;
    display : flex;
    justify-content : center;
    align-items : center;
    transition : 0.5s linear;

    :active& {
        opacity : 0.5
    }
`

export const Input = props => {
    return (
        <Wrapper 
            width = { props.width } 
            height = { props.height }
            style = { props.style }
        >   
            <InnerWrapper 
                reverse = { props.reverse }
            >
                {
                    props.icon ? 
                    <IconWrapper onClick = { props.onIconClick }>
                        { props.icon }
                    </IconWrapper>
                        :
                    <div/>
                }
                <input 
                    style = {{
                        flex : 9,
                        paddingLeft : '5%',
                        paddingRight : '5%',
                        backgroundColor : 'transparent',
                        border : 0,
                    }}
                    placeholder = { props.placeholder }
                    maxLength = { props.maxLength }
                    type = { props.type }
                    onChange = { props.onChange }
                    value = { props.value }
                    min = { props.min }
                    max = { props.max }
                    onKeyPress = { props.onKeyPress }
                />
            </InnerWrapper>
        </Wrapper>
    )
}
