import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { StaticQuery, graphql } from 'gatsby'

// Constant 
const flagStyle = { 
    margin : 0, 
    fontSize: '2em',
    cursor: 'pointer'
}

export const Flag = props => <RedirectLink country = { props.country }/>

const RedirectLink = props => {

    const handleClick = path => {
        window.location.href = path
    }

    return (
      <StaticQuery
        query = {
            graphql`
                query FlagIconQuery {
                    datoCmsOutbound {
                        canadaWebstoreUrl
                        internationalWebstoreUrl
                    }
                }
            `
        }
        render = { data => {
            if ( props.country === 'ca' ) return  <ReactCountryFlag onClick = { () => handleClick( data.datoCmsOutbound.canadaWebstoreUrl ) } countryCode = {'CA'} svg style = { flagStyle }/>
            if ( props.country === 'us' ) return <ReactCountryFlag onClick = { () => handleClick( data.datoCmsOutbound.internationalWebstoreUrl ) } countryCode = {'US'} svg style = { flagStyle }/>
            return <div/>
        }}
      />
    )
}