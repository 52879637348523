import React from 'react'
import styled from 'styled-components'

// Component
import { Link } from 'gatsby'
import Card from 'react-bootstrap/Card'

// Css
import '@fortawesome/fontawesome-free/css/all.css'

const ProductDisplay = styled( Card )`
    margin : 0.5vw;
    border: 0;
    border-radius:0;
    box-shadow: 0 0 3px ${ props  => props.sale ?  '#7e57c2' : 'grey' };
    transition : 0.25s linear;
    margin: 0.5vw;

    :hover&{
        box-shadow: 0 0 10px ${ props  => props.sale ?  '#7e57c2' : 'grey' };
    }
`

export const Product = props => {
    return(
        <Link 
            to = { `/product/` + props.handle } 
            className = 'col-sm-12 col-md-6 col-by-4' 
        >
            <ProductDisplay 
                sale = { props.compareAtPrice }
            >
                <div
                    style = {{
                        width: '100%',
                        paddingTop: '100%',
                        position: 'relative',
                        backgroundColor: 'rgba( 0, 0, 0, 0.4 )'
                    }}
                >
                    <Card.Img 
                        variant = 'top'
                        src = { props.image ? props.image : null } 
                        style = {{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 10,
                        }}
                        alt = { props.title }
                    />
                    {
                        props.compareAtPrice && (
                            <div
                                style = {{
                                    width: '40%',
                                    paddingTop: '40%',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 100,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <i className = "fas fa-certificate fa-4x" 
                                    style = {{
                                        position : 'absolute', 
                                        zIndex : '-1', 
                                        color : 'red' ,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} 
                                />
                                <div
                                    style = {{
                                        position : 'absolute', 
                                        zIndex : '100', 
                                        color : 'white' ,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} 
                                >
                                    {   ( 100* ( 1 - props.price / props.compareAtPrice ) ).toFixed(0)  }%
                                </div>
                            </div>
                        )
                    }
                </div>
                <Card.Body>
                    <Card.Text 
                        style = {{ 
                            color: '#607d8b' ,
                            width: '100%',
                            paddingTop: '60%',
                            position:'relative'
                        }}
                    >
                        <span
                            style = {{
                                position: 'absolute',
                                top:0,
                                bottom:0,
                                left:0,
                                right:0,
                            }}
                        >
                            { props.title }
                        </span>
                    </Card.Text>
                </Card.Body>
            </ProductDisplay>
        </Link>
    )
}