import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = props => {

  const title = props.title ? `${ props.title }` : 'Home of Party Essentials'
  const description = props.description || props.title
  const index = props.index ? 'index, follow'  : 'noindex, nofollow'
  const { structuredData } = props

  return (
    <Helmet>
      <title>{ title  }</title>
      <meta 
        name = 'google-site-verification'
        content = '2LWjKGZh5H6XGetH4Qi_w7J3Exw-qS4OfFcm4jfPh4M'
      />
      <meta 
        name= 'robots'
        content = { index } 
      />
      <meta 
        name = 'description'
        content = { description  } 
      /> 
      <meta 
        name = 'keywords'
        content = { 'Siu and Sons, Siu Sons, Siu & Sons, baby shower , Back to school, back to school, Bakery Box, Balloon, banners, basket, Beach Toy, bead necklace, Birthday, bouncy ball, Bubble, bucket, bulk balloon, bunting, cake pop, candy , Cello wrap, Christmas, consumables, crazy chalk, crazy string, crinkle kraft, Curl Cascade, Curling Ribbon, discount, Easter, Easter egg, ensembles, Favors, Festive, flat wrap, Foil, Foil Decoration, Fold Box, fun, general merchandise, gift bag, gift tags, gift wrap, global sourcing, Glow in dark , grosgrain, gumball dispenser, Halloween, handmade sticker, Happy, Hawaiian, heart, inflatabl ball water sprinkler, invitation card, kraft, lantern, Latex, lei, letter banner, letter foil balloon, licenced balloon, loot bag, Luau, mini gable box, musical candle, musical card, Mylar, New Year, novelties, number, Organza, ornament, packaging, pail, paper, paper shred, paper straw, Patriotic, peva tablecloth, pinata, Plastic cutlery, plastic serveware, Plastic table skirt, pom pom, porcelin, Princess, Qualatex, reflective, Seasonal, sparkler , spring, square bakery box, St. Patrick\'s , Star bow, stationeries, stickers, straw, summer, tablecover, tablecover roll, tableware, Thanksgiving, theme party, tin box, tin canister, tin plate, Tinsel Garland, toys, Tulle, Valentine, value , variety, water bomb, wedding , Window Box, window box with handle, window cling, wine bag, wrap rolls, rack, Balloon weight, DIY' }
      />
      {
        structuredData && (
          <script type = 'application/ld+json'>
            {
              JSON.stringify( structuredData )
            }
          </script>
        )
      }
    </Helmet>
  )
}

export default SEO
