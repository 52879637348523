import React from 'react'
import styled from 'styled-components'

const BannerWrapper = styled.div`
    width: 100vw;
    height: 100vw;
    background-color: ${ props => props.backgroundColor || 'rgba( 0, 50, 100, 0.8 )' };
    display: flex;
    flex-direction: column;

    @media (min-width: 1080px){
        height: 27.5vw;
        flex-direction: row;
    }
`

const Image = styled.div`
    width: 100vw;
    height: ${ 350 / 7 }vw;
    background-image: ${ props => props.src ? `url( ${ props.src } )` : 'none' };
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;

    @media ( min-width: 1080px ){
        width: 35vw;
        height: 27.5vw;
    }
`

const ContentWrapper = styled.div`
    display: none;
    background-image: ${ props => props.src ? `url( ${ props.src } )` : 'none' };
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media ( min-width: 1080px ){
        display: flex;
        width: 40vw;
        height: 27.5vw;
    }
`

const Button = styled.a`
    padding: 1vh;
    margin: 1vh 20%;
    background-color: white;
    color: orange;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ( min-width: 1080px ){
        padding: 1vh;
        margin: 1vh 1% 1vh 69%;
        margin-bottom: 11vh;
    }
`

const ButtonRight = styled.a`
    padding: 1vh;
    margin: 1vh 20%;
    background-color: white;
    color: orange;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ( min-width: 1080px ){
        padding: 1vh;
        margin: 1vh 69% 1vh 1%;
        margin-bottom: 11vh;
    }
`

export const Banner4 = props => {
    return(
        <BannerWrapper
            backgroundColor = { props.backgroundColor }
        >
            <Image 
                src = { props.image1 }
            >
                <Button
                    href = { props.href1 }
                >SHOP NOW</Button>
            </Image>
            <ContentWrapper 
                src = { props.image3 }
            />
            <Image
                src = { props.image2 }
            >
                <ButtonRight
                    href = { props.href2 }
                >SHOP NOW</ButtonRight>
            </Image>
        </BannerWrapper>
    )
}