import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import youtube from "../../images/Youtube.svg"

export const Youtube = props => {
    return(
        <StaticQuery
            query=
                {
                    graphql`
                        query YoutubeQuery{
                            allDatoCmsSocial {
                                edges {
                                    node {
                                        youtube
                                    }
                                }
                            }
                        }
                    `
                }
            render = { data => {
                return (
                    <a href = { data.allDatoCmsSocial.edges[ 0 ].node.youtube }>
                        <img 
                            width = { '40' } 
                            src = { youtube }
                            alt = { 'Youtube' }
                        />
                    </a>
                )
            }}
        />
    )
}