import React, { useState } from 'react'

// Hook
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'

// Components
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'

export const Collaspe = props => {
    return(
        <Card
            style = {{
                border: 0
            }}
        >
            <Card.Header
                style = {{ 
                    backgroundColor : 'white',
                }}
            >
                <Toggle eventKey = { props.eventKey } title = { props.title } />
            </Card.Header>
            <Accordion.Collapse eventKey = { props.eventKey } >
                <Card.Body>
                    { props.children }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

const Toggle = props => {

    const decoratedOnClick = useAccordionToggle( props.eventKey, () =>
      setIsOpened( !isOpened )
    )

    const [ isOpened, setIsOpened ] = useState( false )
  
    return (
        <div
            onClick = { 
                decoratedOnClick
            }
            style = {{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                justifyContent: 'space-between'
            }}
        >
            { props.title }&nbsp;&nbsp;&nbsp;
            {
                isOpened?
                <CloseIcon/>
                    :
                <AddIcon/>
            }
        </div>
    )
  }

