import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

// Components
import { Blog } from './blog'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 767 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
}

export const BlogList = props => {
  return (
    <StaticQuery
        query = {
            graphql`
                query BlogSuggestionQuery {
                    allShopifyArticle(limit: 10) {
                        edges {
                            node {
                                title
                                image {
                                    src
                                }
                                publishedAt
                                handle
                            }
                        }
                    }
                }
            `
        }
        render = {
            data => (
                <Carousel 
                    responsive = { responsive }
                    infinite = { false }
                >
                    {
                        data.allShopifyArticle.edges.map( ( node, index ) => {
                            return (
                                <Blog 
                                    to = { `/blogs/` + node.node.handle } 
                                    key = { index }
                                    image = { node.node.image.src }
                                    title = { node.node.title }
                                    date = { node.node.publishedAt.replace( /T.*/ , '' ) }
                                    style = {{
                                        margin: '0.5vw'
                                    }}
                                />
                            )
                        })
                    }
                </Carousel>
            )
        }
    />
  )
}