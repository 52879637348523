import React from 'react'
import styled from 'styled-components'

const MapWrapper = styled.div`
  display : flex;
  justify-content : center;
  align-items : center;
  cursor : pointer;
  position: relative;
`

const MapLink = styled.a`
  width: 100%;
  height: 100%;
  display : flex;
  justify-content : center;
  align-items : center;
  background-color: rgba( 0, 0 , 0 , 0.5 );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transition: 0.3s linear;
  color: white;
  font-weight: bold;

  :hover&{
    opacity: 1;
  }
`

export const Map = props => {

  if( props.country === 'us' ) {
    return(
      <MapWrapper style = { props.style }>
        <img src = { require('../images/map us.png') } style ={{ width : '100%', height: 'auto', margin: 0 }} alt = 'Address'/>
        <MapLink className = 'link'  target = "_blank" href = 'https://www.google.com/maps/place/230+5th+Ave,+New+York,+NY+10001美國/@40.7439674,-73.9902793,17z/data=!3m1!4b1!4m5!3m4!1s0x89c259a664d8affb:0x4daeae2329f0ac7f!8m2!3d40.7439674!4d-73.9880906'>Open in Google Map</MapLink>
      </MapWrapper>
    )
  }

  return(
    <MapWrapper style = { props.style }>
      <img src = { require('../images/map.png') } style ={{ width : '100%', height: 'auto', margin: 0 }} alt = 'Address'/>
      <MapLink className = 'link'  target = "_blank" href = 'https://www.google.ca/maps/place/Siu+%26+Sons+International+Trading+Corporation/@49.1962051,-123.0750764,17z/data=!3m1!4b1!4m5!3m4!1s0x548675aff7deefdb:0xe43a22ade3644dba!8m2!3d49.1962016!4d-123.0728877'>Open in Google Map</MapLink>
    </MapWrapper>  
  )
}