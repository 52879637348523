import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components'

const Wrapper = styled.div`
    width : ${ props => props.width ? props.width : '100%' };
    height : ${ props => props.height ? props.height : '100%' };
    display : flex;
    justify-content : center;
    align-items : center;
    transition : 0.3s linear;

    :hover& {
        opacity : 0.9
    }
`

export const Menu = props => {
    return(
        <Wrapper>
            <Select
                variant = { 'outlined' }
                style = {{ 
                    backgroundColor : 'white', 
                    height : '90%', 
                    width : '90%',
                    boxShadow : '0 0 5px grey'
                }}
                displayEmpty
                value = { props.value }
                onChange = { props.onChange }
            >   
                <MenuItem value = { '' } disabled>
                    { props.placeholder }
                </MenuItem>
                {
                    props.option ?
                    props.option.map( ( x, index ) => {
                        return <MenuItem value = { x.value } key = { index } >{ x.label }</MenuItem>
                    })
                    :
                    <div/>
                }
                </Select>
        </Wrapper>
    )
}