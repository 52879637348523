import React from 'react'
import styled from 'styled-components'
import { Button as Btn } from '@material-ui/core'

const Wrapper = styled.div`
    width : ${ props => props.width ? props.width : '100%' };
    height : ${ props => props.height ? props.height : '100%' };
    display : flex;
    align-items : center;
    justify-content : center;
`

export const Button = props => {
    return (
        <Wrapper 
            height = { props.height }
            width = { props.width }
            style = { props.style }
        >
            <Btn
                variant = 'contained'
                style = {{
                    height : '90%',
                    width : '90%',
                    backgroundColor: props.primary ? '#009688' : props.secondary ? '#f50057' : '#3f51b5',
                    color: 'white'
                }}
                onClick = { props.onClick }
                disabled = { props.disabled }
            >
                { props.title }
            </Btn>
        </Wrapper>
    )
}

const RoundButtonInner = styled.label`
    background-color : red;
    height : 90%;
    padding-left : 100%;
    position : relative;
    border-radius : 50%
`

export const RoundButton = props => {
    return (
        <Wrapper 
            height = { props.height }
            width = { props.width }
            style = { props.style }
        >
            <RoundButtonInner>
                { props.title }
            </RoundButtonInner>
        </Wrapper>
    )
}